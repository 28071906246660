import * as htmlToImage from "html-to-image";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Divider, Grid, Header, Segment } from "semantic-ui-react";
import Spinner from "../components/Spinner";
import { resetTraitsForAdmin } from "../features/tests/testSlice";
import {
  getAllTraitsFromGroup,
  getSelfTraits,
} from "../features/traits/traitSlice";
import "../styles/johari-window.scss";

const JohariWindow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // states
  const [selfTraits, setSelfTraits] = useState([]);
  const [traitsFromOthers, setTraitsFromOthers] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const { selfTraitsArr, userTraitsFromOthers } = useSelector((state) => state.traits);

  const addTraits = () => {
    if (selfTraitsArr.length >= 1) {
      setSelfTraits(selfTraitsArr);
    }

    if (userTraitsFromOthers.length >= 1) {
      setTraitsFromOthers(userTraitsFromOthers);
    }
  }

  useEffect(() => {
    addTraits();
  }, [selfTraitsArr, userTraitsFromOthers]);

  useEffect(() => {
    dispatch(getSelfTraits());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllTraitsFromGroup());
  }, [dispatch]);

  const [open, setOpen] = useState([]);
  const [hidden, setHidden] = useState([]);
  const [blind, setBlind] = useState([]);

  const setTraits = () => {

    const openTraits = [];
    const hiddenTraits = [];
    const blindTraits = [];

    selfTraits.forEach((selfTrait) => {
      if (traitsFromOthers.includes(selfTrait)) {
        openTraits.push(selfTrait);
      }
      if (!traitsFromOthers.includes(selfTrait)) {
        hiddenTraits.push(selfTrait);
      }
    });

    traitsFromOthers.forEach((trait) => {
      if (!selfTraits.includes(trait)) {
        blindTraits.push(trait);
      }
    });

    setOpen(openTraits);
    setHidden(hiddenTraits);
    setBlind(blindTraits);
  }

  useEffect(() => {
    setTraits();
  }, [selfTraits, traitsFromOthers]);

  const saveAs = (blob, fileName) => {
    let elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  const takeScreenshot = (id) => {
    htmlToImage.toPng(document.getElementById(id)).then(function (dataUrl) {
      saveAs(dataUrl, `${user.username}'s-johari-window.png`);
    });
  };

  const resetTraits = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resetTraitsForAdmin()).unwrap();
      toast.success("Traits from others have been reset");
      //reload the page
      window.location.reload();
    } catch (error) {
      console.log("error: ", error);
      toast.error("Error resetting traits");
    }
  };
  



  return (
    <Container style={{ width: "75%"}}>
      <Header textAlign="center" as="h2" style={{ marginTop: "3em" }}> Peer Assessment Window for {user.title}</Header>
      <Segment>
        <section className="johari" id="capture">
          <div className="johari-container">
            <h3 className="item item-top">Known to self</h3>
            <h3 className="item">Not known to self</h3>
            <h3 className="item">Known to others</h3>
            <div className=" box box-open">
              <h4>Open</h4>
              <div className="word-container">
                {open &&
                  open.map((trait, index) => (
                    <div className="words" key={index}>
                      {trait}{index < open.length - 1 && ","}
                    </div>
                  ))}
              </div>
            </div>
            <div className=" box box-blind">
              <h4>Blind</h4>
              <div className="word-container">
                {blind &&
                  blind.map((trait, index) => (
                    <div className="words" key={index}>
                      {trait}{index < blind.length - 1 && ","}
                    </div>
                  ))}
              </div>
            </div>
            <h3 className="item">Not known to others</h3>
            <div className=" box box-hidden">
              <h4>Hidden</h4>
              <div className="word-container">
                {hidden &&
                  hidden.map((trait, index) => (
                    <div className="words" key={index}>
                      {trait}{index < hidden.length - 1 && ","}
                    </div>
                  ))}
              </div>
            </div>
            <div className=" box box-unknown">
              <h4>Unknown</h4>
            </div>
          </div>
        </section>
        <Divider />
        <Grid container columns={2} stackable>
          
          <Grid.Column textAlign="center">
            <Button
              onClick={() => takeScreenshot("capture")}
              style={{ paddingLeft: "2em", paddingRight: "2em"  }}
              color="blue"
              fluid
              size="huge"
              content="Download Peer Assessment"
            />
          </Grid.Column>
          
          {(user.role === "admin" || user.role === "facilitator" ) && (
            
            <Grid.Column textAlign="center">
              <Button
                onClick={resetTraits}
                color="red"
                content="Reset Traits from Others"
                fluid
                size="huge"
              />
            </Grid.Column>
            
          )}
        </Grid>
      </Segment>
    </Container>
  );
};
export default JohariWindow;
