import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";

import {
  getAll,
  getAllMembersForGroup,
  getMembers,
} from "../features/members/memberSlice";
import { createTest } from "../features/tests/testSlice";
import {
  createTraits,
  createTraitsForOthers,
  getAdminSelf,
  getAdminTraitsFromGroup,
  getAllTraitsFromGroup,
  getSelfTraits,
} from "../features/traits/traitSlice";
import traits from "../helperFunctions/traitsArr";
import "../styles/peer-assessment.scss";

const JohariTest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, groupid } = useParams();

  const { user } = useSelector((state) => state.auth); //login user info
  const { membersArr, allMembers } = useSelector((state) => state.members);
  const { isLoading: isLoadingTst } = useSelector((state) => state.tests);
  const { isLoading: isLoadingTrt } = useSelector((state) => state.traits);

  const [currentMember, setCurrentMember] = useState("");
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [testData, setTestData] = useState({
    examineeid: "",
    examinerid: "",
    groupid: groupid,
    testid: uuidv4(),
  });

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    if(user.role !== "admin") {
      dispatch(getAllMembersForGroup());
    }
  }, [dispatch]);

  const setmember = () => {
    if (allMembers.length > 0) {
      const member = allMembers.find((member) => member.id === id);

      if((user.role === "admin" || user.role === "facilitator") && id === String(user.id)) {
        setCurrentMember(user);
        setTestData((prevTestData) => ({ ...prevTestData, examineeid: id, examinerid: user.id }));
      } else {
        setCurrentMember(member);
        setTestData((prevTestData) => ({ ...prevTestData, examineeid: id, examinerid: user.id }));
      }
    }
  }

  console.log("membersArr in test", membersArr);

  console.log("membersArr in test", membersArr);
  console.log("currentMember in test", currentMember);
  console.log("groupid in test", groupid);
  console.log("testData in test", testData);

  useEffect(() => {
    setmember();
  }, [allMembers]);

  console.log("currentMember", currentMember);
  console.log(selectedTraits);

  const onSubmit = async () => {

    if (selectedTraits.length <= 4) {
      return toast.error("Please select 4-5 traits");
    }

    try {
      await dispatch(createTest(testData)).unwrap(); //we wait for the test to be created before we can add traits
      if (testData.examineeid === testData.examinerid) {
        //send each of the selected traits to the backend
        selectedTraits.forEach(async (trait) => {
          await dispatch(createTraits({ testid: testData.testid, trait })).unwrap();
        });
        toast.success("Test submitted successfully");
      } else {
        selectedTraits.forEach(async (trait) => {
          await dispatch(createTraitsForOthers({ testid: testData.testid, trait })).unwrap();
        });
        toast.success("Test submitted successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again");
    } finally {
      if (user.role !== "admin") {
        dispatch(getSelfTraits());
        dispatch(getAdminTraitsFromGroup(groupid));
        navigate("/");
      } else {
        dispatch(getAdminSelf(groupid));
        dispatch(getAllTraitsFromGroup(groupid));
        dispatch(getMembers(groupid));
        navigate(`/${groupid}`);
      }
    }
  }

  // Used to select and deselect traits
  const selectedClick = (trait) => {
    if (!selectedTraits.includes(trait) && selectedTraits.length <= 4) {
      return setSelectedTraits([...selectedTraits, trait]);
    }
    setSelectedTraits(
      selectedTraits.filter((selectedTrait) => selectedTrait !== trait)
    );
  };

  //this is shown to the user to select traits for the johari window for the first time

  return (
    <Container>
      <Header textAlign="center" as="h2" style={{ marginTop: "3em" }}>Complete Peer Assessment for {currentMember?.name} {currentMember?.lastname}
          <Header.Subheader> Please select 5 traits for user {currentMember?.username} </Header.Subheader>
      </Header>
      <Segment>
        {/* add a grid with 8 rows and 5 cloumns to display semantic ui buttons as an item of traits */}
        <Grid columns={5} centered stackable textAlign="center" verticalAlign="middle">
          {traits.map((trait) => (
            <Grid.Column key={trait}>
              <Button fluid size="big" onClick={() => selectedClick(trait)} color={selectedTraits.includes(trait) ? "teal" : "grey"}>{trait}</Button>
            </Grid.Column>
          ))}
        </Grid>
        <Divider />
        <Grid textAlign="center" verticalAlign="middle" columns={2}>
          <Grid.Column>
           <Button size="big" fluid onClick={onSubmit} color="green" loading={isLoadingTst || isLoadingTrt}>Submit</Button>
          </Grid.Column>
          <Grid.Column>
            <Button size="big" fluid onClick={() => {
              if (user.role !== "admin") {
                navigate("/");
              } else {
                navigate(`/${groupid}`);
              }
            } } color="red">Cancel</Button>
          </Grid.Column>
        </Grid>

      </Segment>
    </Container>
  );
};
export default JohariTest;
