const traits = [
  "Adaptable",
  "Adventurous",
  "Authoritarian",
  "Calm",
  "Charismatic",
  "Confident",
  "Contributor",
  "Cooperative",
  "Creative",
  "Dedicated",
  "Efficient",
  "Elegant",
  "Eloquent",
  "Empathetic",
  "Extroverted",
  "Flexible",
  "Fun",
  "Generous",
  "Genuine",
  "Helpful",
  "Honest",
  "Humble",
  "Idealistic",
  "Independent",
  "Insightful",
  "Introverted",
  "Kind",
  "Logical",
  "Methodical",
  "Meticulous",
  "Observant",
  "Open-Minded",
  "Optimistic",
  "Organized",
  "Personable",
  "Reflective",
  "Sociable",
  "Trustworthy",
  "Wise",
  "Witty",
];

export default traits;
