import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import Dashboard from "./pages/Dashboard";
import DashboardForGroup from "./pages/DashboardForGroup";
import ErrorPage from "./pages/ErrorPage";
import GroupSelect from "./pages/GroupSelect";
import JohariTest from "./pages/JohariTest";
import JohariTestEdit from "./pages/JohariTestEdit";
import JohariWindow from "./pages/JohariWindow";
import Login from "./pages/Login";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/group-select" element={<GroupSelect />} />
            <Route path="/:id" element={<DashboardForGroup />} />
            <Route path="/:groupid/johari-test/users/:id" element={<JohariTest />} />
            <Route path="/:groupid/johari-test/test/:id/edit" element={<JohariTestEdit />} />
            <Route path="/johari-window" element={<JohariWindow />} />
          </Route>

          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
