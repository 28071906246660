import { useNavigate } from "react-router-dom";
import { Button, Card } from "semantic-ui-react";

const IncompleteItem = ({ member, groupid }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/${groupid}/johari-test/users/${member.id}`);
  };

  return (
    <Card raised color="green" link onClick={onClick}>
      <Card.Content>
        <Card.Header>Traits for {member.name + " " + member.lastname}</Card.Header>
        <Card.Meta>Select to complete</Card.Meta>
        <Card.Description>Test Missing</Card.Description>
      </Card.Content>
    </Card>
  );
};
export default IncompleteItem;
