import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  getAll,
  getAllMembersForGroup,
  getMembers,
} from "../features/members/memberSlice";
import {
  editTraits,
  getAdminSelf,
  getAdminTraitsFromGroup,
  getAllTraitsForTest,
  getAllTraitsFromGroup,
  getSelfTraits,
  resetTraits,
} from "../features/traits/traitSlice";
import traits from "../helperFunctions/traitsArr";

import { Button, Container, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { getAllTests } from "../features/tests/testSlice";
import "../styles/peer-assessment.scss";

const JohariTestEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, groupid } = useParams();
  const { user, isLoading, isError, message } = useSelector(
    (state) => state.auth
  );
  const { testsArr } = useSelector((state) => state.tests);
  const { currentTestTraits } = useSelector((state) => state.traits);
  const { allMembers } = useSelector((state) => state.members);

  useEffect(() => {
    if(user.role !== "admin") {
      dispatch(getAllMembersForGroup());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllTests());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  // states
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [currentTest, setCurrentTest] = useState(null);
  const [currentMember, setCurrentMember] = useState("");

  const setTest = () => {
    if (testsArr.length > 0) {
      const test = testsArr.find((test) => test.id === id);
      setCurrentTest(test);
    }
  }
  
  const setMember = () => {
    if (currentTest) {
      const member = allMembers.find((member) => member.id === currentTest.examineeid);
      setCurrentMember(member);
    }
  }

  useEffect(() => {
    dispatch(getAllTraitsForTest(id));
  }, [dispatch]);

  useEffect(() => {
    setSelectedTraits(currentTestTraits);
  }, [currentTestTraits]);
  
  useEffect(() => {
    setTest();
  }, [testsArr]);

  useEffect(() => {
    setMember();
  }, [allMembers, currentTest]);

  // Used to select and deselect traits
  const selectedClick = (trait) => {
    if (!selectedTraits.includes(trait) && selectedTraits.length <= 4) {
      return setSelectedTraits([...selectedTraits, trait]);
    }
    setSelectedTraits(
      selectedTraits.filter((selectedTrait) => selectedTrait !== trait)
    );
  };

  const onSubmit = () => {
    if (selectedTraits.length < 4) {
      return alert("Please select 4-5 traits");
    }
    try {
      selectedTraits.forEach(async (trait) => {
        await dispatch(editTraits({ testid: currentTest.id, trait })).unwrap();
      });
    } catch (error) {
      console.error(error);
    } finally {
      if (user.role !== "admin") {
        dispatch(getAllTraitsFromGroup());
        dispatch(getSelfTraits());
        dispatch(getAllMembersForGroup());
        navigate("/");
      } else {
        dispatch(getAdminSelf(groupid));
        dispatch(getAdminTraitsFromGroup(groupid));
        dispatch(getMembers(groupid));
        navigate(`/${groupid}`);
      }
    }
    
  };

  //this is shown to the user to edit the already selected states for the johari window

  return (
    <Container>
      <Header textAlign="center" as="h2" style={{ marginTop: "3em" }}>Edit Peer Assessment for {currentMember?.name} {currentMember?.lastname}
          <Header.Subheader> Please select 5 traits for user {currentMember?.username} </Header.Subheader>
      </Header>
      <Segment>
        {/* add a grid with 8 rows and 5 cloumns to display semantic ui buttons as an item of traits */}
        <Grid columns={5} centered stackable textAlign="center" verticalAlign="middle">
          {traits.map((trait) => (
            <Grid.Column key={trait}>
              <Button fluid size="big" onClick={() => selectedClick(trait)} color={selectedTraits.includes(trait) ? "teal" : "grey"}>{trait}</Button>
            </Grid.Column>
          ))}
        </Grid>
        <Divider />
        <Grid textAlign="center" verticalAlign="middle" columns={2}>
          <Grid.Column>
           <Button size="big" fluid onClick={onSubmit} color="green">Submit</Button>
            
          </Grid.Column>
          <Grid.Column>
            <Button size="big" fluid onClick={() => {
              if (user.role !== "admin") {
                navigate("/");
              } else {
                navigate(`/${groupid}`);
              }
            } } color="red">Cancel</Button>
          </Grid.Column>
        </Grid>

      </Segment>
    </Container>
  );
};
export default JohariTestEdit;
