import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Container, Divider, Header, Icon, Message, Segment } from "semantic-ui-react";
import CompletedItem from "../components/CompletedItem";
import IncompleteItem from "../components/IncompleteItem";
import { getAllGroups } from "../features/groups/groupSlice";
import { getAll, getMembers } from "../features/members/memberSlice";
import { getAllTests } from "../features/tests/testSlice";
import { getSelfTraits } from "../features/traits/traitSlice";

const DashboardForGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isError, message } = useSelector(
    (state) => state.auth
  );
  const { allMembers, membersArr, isLoading } = useSelector((state) => state.members);
  const { groupsArr } = useSelector((state) => state.groups);

  const { testsArr } = useSelector((state) => state.tests);
  const [completed, setCompleted] = useState([]);
  const [incompleted, setIncompleted] = useState([]);
  const [facilitatorCompleted, setFacilitatorCompleted] = useState([]);
  const [facilitatorIncompleted, setFacilitatorIncompleted] = useState([]);
  const [isFacilitator, setIsFacilitator] = useState(false); // check if (login) user is a facilitator of the group


  // selected groupid
  const { id } = useParams();

  useEffect(() => {
    if (isError) toast.error(message);
    // if (user.role !== "admin") {
    //   return navigate("/");
    // }
  }, [user, navigate, dispatch, isError, message]);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMembers(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllTests());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  const selectedGroup = groupsArr?.find(group => group.id === id);

  const categorizeTestCompletion = () => {
    const facilitators = allMembers.filter(member => membersArr[0] && membersArr[0].facilitator?.split(", ").includes(member.id));

    if (facilitators.find(facilitator => facilitator.id === user.id)) {
      setIsFacilitator(true);
    }

    const allParticipants = [...membersArr, ...facilitators].filter((member) => member.withdrawal !== 1); // filter out withdrawn members so they don't show up in the dashboard
    const newCompleted = [];
    const newIncompleted = [];
    const newFacilitatorCompleted = [];
    const newFacilitatorIncompleted = [];

    allParticipants.forEach((participant) => {
      let isCompleted = false;
      testsArr.forEach((test) => {
        if (test.examineeid === participant.id) {
          if (facilitators.includes(participant)) {
            newFacilitatorCompleted.push({ member: participant, test: test });
          } else {
            newCompleted.push({ member: participant, test: test });
          }
          isCompleted = true;
        }
      });
      if (!isCompleted) {
        if (facilitators.includes(participant)) {
          newFacilitatorIncompleted.push(participant);
        } else {
          newIncompleted.push(participant);
        }
      }
    });

    setCompleted(newCompleted);
    setIncompleted(newIncompleted);
    setFacilitatorCompleted(newFacilitatorCompleted);
    setFacilitatorIncompleted(newFacilitatorIncompleted);
  };

  useEffect(() => {
    categorizeTestCompletion();
  }, [membersArr, testsArr, allMembers]);

  const onClick = (e) => {
    e.preventDefault();
    if (incompleted.length > 0) {
      toast.error("Please complete all tests");
      return;
    } else {
      navigate("/johari-window");
    }
  };

  //this is for the admin to view the selected group

  return (
    <Container>
      <Header as="h1" textAlign="center" style={{ marginTop: "3em" }}> Welcome to the Peer Assessment Panel</Header>
      <Segment loading={isLoading} >
        {
          membersArr.length > 0 ? (
            <Segment basic>
                <Header as="h2" textAlign="center">Members for Group {selectedGroup?.groupname.charAt(0).toUpperCase() + selectedGroup?.groupname.slice(1)}
                  <Header.Subheader> Please complete all tests before proceeding</Header.Subheader>
                </Header>
                {
                  !isFacilitator && (
                    <Message warning>
                      <Message.Header>You are not a facilitator for this group</Message.Header>
                      <p>You can test the members of the group but the members will not be able to test you</p>
                      <p>Please add yourself as a facilitator in User Management so that you can be tested</p>
                    </Message>
                  )
                }
                <Card.Group centered stackable itemsPerRow={3}>
                {membersArr.length > 0 ? (
                  <>
                    
                    {incompleted.length > 0 &&
                      incompleted.map((member, index) => (
                        <IncompleteItem key={index} member={member} groupid={id} />
                      ))}
                    {completed.map(({ member, test }, index) => (
                      <CompletedItem key={index} member={member} test={test} groupid={id} />
                    ))}
                  </>
                ) : (
                  <Header as="h3" textAlign="center">No members to show</Header>
                )}
                </Card.Group>
                {
                  (facilitatorCompleted.length > 0 || facilitatorIncompleted.length > 0) && (
                    <Divider horizontal section > <Header as="h3" textAlign="center">(Optional) Facilitators</Header></Divider>
                  )
                }
                <Card.Group centered stackable itemsPerRow={3}>
                {membersArr.length > 0 ? (
                  <>
                    {facilitatorIncompleted.length > 0 && facilitatorIncompleted.map((member, index) => (
                      <IncompleteItem key={index} member={member} groupid={id} />
                    ))}
                    {facilitatorCompleted.map(({ member, test }, index) => (
                      <CompletedItem key={index} member={member} test={test} groupid={id} />
                    ))}
                  </>
                ) : (
                  <Header as="h3" textAlign="center">No members to show</Header>
                )}
                </Card.Group>
            </Segment>
          ) : (
            <Header as="h3" textAlign="center">No members to show</Header>
          )
        }
        <Divider section />
        <Container textAlign="center">
            <Button color="blue" size="huge" fluid onClick={onClick} disabled={incompleted.length > 0}>Results</Button>
        </Container>
      </Segment>
    </Container>
  );
};
export default DashboardForGroup;
