import { useNavigate } from "react-router-dom";
import { Button, Card } from "semantic-ui-react";

const CompletedItem = ({ member, test, groupid}) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/${groupid}/johari-test/test/${test.id}/edit`);
  };


  return (
    <Card raised color="blue" link onClick={onClick}>
      <Card.Content>
        <Card.Header>Traits for {member.name + " " + member.lastname}</Card.Header>
        <Card.Meta>Select to edit</Card.Meta>
        <Card.Description>Test Completed</Card.Description>
      </Card.Content>
    </Card>
  );
};
export default CompletedItem;
