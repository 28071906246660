import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/members/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/members/";
}


// GET all Members for user's group
const getAllMembersForGroup = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL, config);
  return data;
};
// GET all Members for a specific group
const getMembers = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL + id, config);
  return data;
};

// GET all Members for a specific group
const getAll = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL + "all", config);
  return data;
};

const memberService = { getAllMembersForGroup, getMembers, getAll };

export default memberService;
