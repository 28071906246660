import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/tests/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/tests/";
}


// GET all Tests That the user has completed
const getAllTests = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL, config);
  return data;
};

// GET all tests that the admin user has completed for this specific group
const getAdminTestsForGroup = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL + id, config);
  return data;
};

const createTest = async (testData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.post(API_URL, testData, config);
  return data;
};

const resetTraitsForAdmin = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.delete(API_URL, config);
  return data;
};

const testService = {
  getAllTests,
  createTest,
  getAdminTestsForGroup,
  resetTraitsForAdmin,
};

export default testService;
