import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";
import logo from "../assets/FL2F-logo.png";
import { logout, reset } from "../features/auth/authSlice";
import "../styles/header.scss";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { selfTraitsArr } = useSelector((state) => state.traits);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };

  if (location.pathname === "/login") return null;

  return (
    <>
      <Menu size="huge" className="desktop-menu">
        <Menu.Item as={Link} to="/">
          <Image src={logo} size="small" />
        </Menu.Item>
        <Menu.Menu position="right">
          {
            user?.role !== "admin" && (
              selfTraitsArr.length > 0 && (
                <Menu.Item as={Link} to="/johari-window">
                  Results
                </Menu.Item>
              )
            )
          }
          <Menu.Item name="Logout" onClick={onLogout}>
            Logout
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </>
  );
};
export default Navbar;
