import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Header, Segment, Tab, Table } from "semantic-ui-react";
import { getAllGroups } from "../features/groups/groupSlice";
import { testsReset } from "../features/tests/testSlice";
import { resetTraits } from "../features/traits/traitSlice";

const GroupSelect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { groupsArr, isLoading } = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  const [column, setColumn] = useState("startdate");
  const [direction, setDirection] = useState("descending");

  const handleSort = (clickedColumn) => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection('ascending');
      return;
    }
    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
  };

  const sortedGroups = useMemo(() => {
    const data = [...groupsArr].filter(group => group.grouprole !== "admin" && group.grouprole !== "guest");
    if (column !== null) {
      data.sort((a, b) => {
        const fieldA = a[column] ? a[column].toString().toLowerCase() : '';
        const fieldB = b[column] ? b[column].toString().toLowerCase() : '';
        if (direction === 'ascending') {
          return fieldA.localeCompare(fieldB);
        } else {
          return fieldB.localeCompare(fieldA);
        }
      });
    }
    return data;
  }, [groupsArr, column, direction]);

  return (
    <Container textAlign="center">
      <Header as="h1" textAlign="center" style={{ marginTop: "2em" }}> Peer Assessment Panel
        <Header.Subheader>Please select a group to view the members</Header.Subheader>
      </Header>
      <Segment size="huge" secondary loading={isLoading}>
        <Table sortable textAlign="center" compact padded selectable size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={column === 'groupname' ? direction : null} onClick={() => handleSort('groupname')}>Group Name</Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'startdate' ? direction : null} onClick={() => handleSort('startdate')}>Start Date</Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'grouprole' ? direction : null} onClick={() => handleSort('grouprole')}>Group Role</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedGroups.map((group) => (
              <Table.Row key={group.id} onClick={() => navigate(`/${group.id}`)}>
                <Table.Cell>{group.groupname.charAt(0).toUpperCase() + group.groupname.slice(1)}</Table.Cell>
                <Table.Cell>{new Date(group.startdate).toLocaleDateString('en-US', { year: "numeric", month: "long", day: "numeric" })}</Table.Cell>
                <Table.Cell>{group.grouprole.charAt(0).toUpperCase() + group.grouprole.slice(1)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </Container>
  );
};
export default GroupSelect;
