import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoutes = () => {
    const { user } = useSelector((state) => state.auth);
    const location = useLocation();

    return user && user.id ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
